import logo from "./logo.png";

export const config = {
  API_URL: 'https://afdprojipacpre7000.azurefd.net',
  COMPANY_NAME: "Atlantic",
  COPYRIGHT: (new Date()).getFullYear() + " ATLANTIC",
  COMPANY_URL: "https://www.atlantic.fr/",
  LOGO: logo,
  HEADER_DATASOURCE : { Authorization: "7sTE8af93B2HACwh6NcSn43h36FUu8Aba5s" },
  TITLE: "Projipac4",
  AUTHORIZED_UPLOAD: ["mp4"],
  ENV: 'preprod'
};
